<template>
  <CHeader
    fixed
    with-subheader
    light
    class="text-white"
    style="
      background-color: #6a93cb;
      background-image: linear-gradient(
        315deg,
        rgb(125 158 204) 0%,
        rgb(26 53 94) 74%
      );
    "
  >
    <CToggler
      in-header
      class="ml-3 d-lg-none text-white"
      @click="$store.commit('responsive/toggleSidebarMobile')"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none text-white"
      @click="$store.commit('responsive/toggleSidebarDesktop')"
    />
    <CHeaderBrand class="mx-auto d-lg-none" to="/">
      <!-- <img
        :src="require('@/assets/images/logo-siakang.png')"
        height="20"
        alt="Logo"
      /> -->
    </CHeaderBrand>
    <CHeaderNav class="d-md-down-none mr-auto">
      <CHeaderNavItem class="px-3">
        <!-- <CHeaderNavLink to="/dashboard">
          Dashboard
        </CHeaderNavLink> -->
      </CHeaderNavItem>
    </CHeaderNav>
    <CHeaderNav class="mr-5">
      <TheHeaderDropdownAccnt />
    </CHeaderNav>
    <CSubheader class="px-3">
      <CBreadcrumbRouter class="border-0 mb-0" />
    </CSubheader>
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from "./TheHeaderDropdownAccnt";

export default {
  name: "TheHeader",
  components: {
    TheHeaderDropdownAccnt,
  },
};
</script>
