function isCanAccess(item, user) {
  if (user) {
    if (user.id == 1 || user.id == 7) {
      return true
    } else {
      if (user.role) {
        if (user.role.assignments)
          if (item[1] == null)
            return user.role.assignments[item[0]]['index'] == 1;
        return user.role.assignments[item[0]][item[1]]['index'] == 1;
      }
    }
  }
  return false
}

function isSuperAdmin(user) {
  if (user) {
    if (user.id == 1 || user.id == 7) {
      return [
        {
          _name: "CSidebarNavDropdown",
          name: "Management User",
          route: "/management-users/users",
          icon: "cil-puzzle",
          items: [
            {
              name: "Users",
              to: "/management-users/users",
            },
            {
              name: "Role",
              to: "/management-users/roles",
            },
          ],
        }];
    }
  }
  return false;
}

function admin(user) {
  const item = []
  if (isCanAccess(['admin', 'kualifikasi_jabatan_struktural'], user))
    item.push(
      {
        name: "Kualifikasi Jabatan Struktural",
        to: "/admin/kualifikasi-jabatan-struktural",
      })
  if (isCanAccess(['admin', 'abk_jabatan_umum'], user))
    item.push(
      {
        name: "ABK Jabatan Umum",
        to: "/admin/abk-jabatan-umum",
      })
  // issue no 49, hide this menu
  // if (isCanAccess(['admin', 'informasi_jabatan_umum'], user))
  //   item.push(
  //     {
  //       name: "Informasi Jabatan Umum",
  //       to: "/admin/informasi-jabatan-umum",
  //     })
  if (isCanAccess(['admin', 'abk_jabatan_fungsional'], user))
    item.push(
      {
        name: "ABK Jabatan Fungsional",
        to: "/admin/abk-jabatan-functional",
      })
  // issue no 49, hide this menu
  // if (isCanAccess(['admin', 'informasi_jabatan_functional'], user))
  //   item.push(
  //     {
  //       name: "Informasi Jabatan Fungsional",
  //       to: "/admin/informasi-jabatan-functional",
  //     })

  return item.length > 0 ? [
    {
      _name: "CSidebarNavDropdown",
      name: "Admin",
      route: "/admin",
      icon: "cil-puzzle",
      items: item,
    },] : false
}

function layanan(user) {
  const item = [];
  if (isCanAccess(['layanan', 'jenis_layanan'], user))
    item.push(
      {
        name: "Jenis Layanan",
        to: "/layanan/jenis-layanan",
      })
  if (isCanAccess(['layanan', 'persyaratan_layanan'], user))
    item.push(
      {
        name: "Persyaratan Pelayanan",
        to: "/layanan/persyaratan-pelayanan",
      })


  return item.length > 0 ? [
    {
      _name: "CSidebarNavDropdown",
      name: "Layanan",
      route: "/layanan",
      icon: "cil-puzzle",
      items: item,
    },] : false
}

// function usulanLayanan(user) {
//   const item = [];

//   if (isCanAccess(['usulan_layanan', 'usulan_kenaikan_pangkat'], user))
//     item.push(
//       {
//         name: "Usulan Kenaikan Pangkat",
//         to: "/usulan-layanan/usulan-kenaikan-pangkat",
//       }
//     )

//   if (isCanAccess(['usulan_layanan', 'usulan_mutasi_masuk'], user))
//     item.push(
//       {
//         name: 'Usulan Mutasi Masuk',
//         to: '/usulan-layanan/usulan-mutasi-masuk'
//       },
//     )

//   if (isCanAccess(['usulan_layanan', 'usulan_mutasi_keluar'], user))
//     item.push(
//       {
//         name: "Usulan Mutasi Keluar",
//         to: "/usulan-layanan/usulan-mutasi-keluar",
//       },
//     )

//   if (isCanAccess(['usulan_layanan', 'pencantuman_gelar'], user))
//     item.push(
//       {
//         name: 'Pencantuman Gelar',
//         to: '/usulan-layanan/pencantuman-gelar'
//       },
//     )

//   if (isCanAccess(['usulan_layanan', 'sk_kenaikan_pangkat'], user))
//     item.push(
//       {
//         name: 'P&P SK Kenaikan Pangkat',
//         to: '/usulan-layanan/perbaikan-pergantian'
//       },
//     )

//   if (isCanAccess(['usulan_layanan', 'sk_pengangkatan'], user))
//     item.push(
//       {
//         name: 'P&P SK Pengangkatan',
//         to: '/usulan-layanan/perbaikan-pergantian-pengangkatan'
//       },
//     )


//   return item.length > 0 ? [
//     {
//       _name: "CSidebarNavDropdown",
//       name: "Usulan Layanan",
//       route: "/usulan-layanan",
//       icon: "cil-puzzle",
//       items: item,
//     },] : false
// }

// function verifikasiLayanan(user) {
//   const item = [];
//   if (isCanAccess(['verifikasi_layanan', 'kenaikan_pangkat'], user))
//     item.push(
//       {
//         name: 'Kenaikan Pangkat',
//         to: '/verifikasi-layanan/verifikasi-kenaikan-pangkat'
//       })

//   if (isCanAccess(['verifikasi_layanan', 'usulan_mutasi_masuk'], user))
//     item.push(
//       {
//         name: 'Mutasi Masuk',
//         to: '/verifikasi-layanan/verifikasi-usulan-mutasi-masuk'
//       })

//   if (isCanAccess(['verifikasi_layanan', 'pencantuman_gelar'], user))
//     item.push(
//       {
//         name: 'Pencantuman Gelar',
//         to: '/verifikasi-layanan/verifikasi-pencantuman-gelar'
//       })

//   if (isCanAccess(['verifikasi_layanan', 'pnp_sk_kenaikan_pangkat'], user))
//     item.push(
//       {
//         name: 'P&P SK Kenaikan Pangkat',
//         to: '/verifikasi-layanan/verifikasi-perbaikan-pergantian'
//       })

//   if (isCanAccess(['verifikasi_layanan', 'pnp_sk_pengangkatan'], user))
//     item.push({
//       name: 'P&P SK Pengangkatan',
//       to: '/verifikasi-layanan/verifikasi-perbaikan-pergantian-pengangkatan'
//     })

//   if (isCanAccess(['verifikasi_layanan', 'usulan_mutasi_masuk'], user))
//     item.push({
//       name: "Mutasi Keluar",
//       to: "/verifikasi-layanan/verifikasi-mutasi-keluar",
//     })

//   return item.length > 0 ? [{
//     _name: "CSidebarNavDropdown",
//     name: "Verifikasi Layanan",
//     route: "/verifikasi-layanan",
//     icon: "cil-puzzle",
//     items: item
//   }] : false
// }

function laporan(user) {
  const item = [];
  if (isCanAccess(['laporan', 'per-opd-skpd'], user))
    item.push(
      {
        name: 'Per OPD/SKPD',
        to: '/laporan/per-opd-skpd'
      })

  if (isCanAccess(['laporan', 'per-eselon'], user))
    item.push(
      {
        name: 'Per Eselon',
        to: '/laporan/per-eselon'
      })

  if (isCanAccess(['laporan', 'per-jenis-kelamin'], user))
    item.push(
      {
        name: 'Per Jenis Kelamin',
        to: '/laporan/per-jenis-kelamin'
      })

  if (isCanAccess(['laporan', 'per-golongan'], user))
    item.push(
      {
        name: 'Per Golongan',
        to: '/laporan/per-golongan'
      })

  if (isCanAccess(['laporan', 'per-pendidikan'], user))
    item.push(
      {
        name: 'Per Pendidikan',
        to: '/laporan/per-pendidikan'
      })

  return item.length > 0 ? [{
    _name: "CSidebarNavDropdown",
    name: "Laporan",
    route: "/laporan",
    icon: "cil-puzzle",
    items: item
  }] : false
}

async function menu(user) {
  const _children = [
    {
      _name: 'CSidebarNavItem',
      name: 'Home',
      to: '/dashboard',
      icon: 'cil-speedometer',
      badge: {
        color: 'primary',
        text: 'NEW'
      }
    },

  ]

  if (isCanAccess(['uji_kompetensi', null], user))
    _children.push(
      {
        _name: "CSidebarNavItem",
        name: "Uji Kompetensi",
        to: "/uji-kompetensi",
        icon: "cil-pencil",
      })

  if (isCanAccess(['monitoring_jabatan_struktural', null], user))
    _children.push(
      {
        _name: "CSidebarNavItem",
        name: "Monitoring Jabatan Struktural",
        to: "/monitoring-jabatan-struktural",
        icon: "cil-cursor",
      })

  if (isCanAccess(['monitoring_jabatan_fungsional', null], user))
    _children.push(
      {
        _name: "CSidebarNavItem",
        name: "Monitoring Jabatan Fungsional",
        to: "/monitoring-jabatan-fungsional",
        icon: "cil-drop",
      })

  if (isCanAccess(['monitoring_jabatan_umum', null], user))
    _children.push(
      {
        _name: "CSidebarNavItem",
        name: "Monitoring Jabatan Umum",
        to: "/monitoring-jabatan-umum",
        icon: "cil-cursor",
      })

  let adminMenu = admin(user)
  if (adminMenu) {
    _children.push(adminMenu[0])
  }

  // let layananMenu = layanan(user)
  // if (layananMenu) {
  //   _children.push(layananMenu[0])
  // }

  // let usulanLayanannMenu = usulanLayanan(user)
  // if (usulanLayanannMenu) {
  //   _children.push(usulanLayanannMenu[0])
  // }

  // let verifikasiLayananMenu = verifikasiLayanan(user)
  // if (verifikasiLayananMenu) {
  //   _children.push(verifikasiLayananMenu[0])
  // }

  let laporanMenu = laporan(user)
  if (laporanMenu) {
    _children.push(laporanMenu[0])
  }

  let masterMenu = isSuperAdmin(user)
  if (masterMenu) {
    _children.push(masterMenu[0])
  }


  return [
    {
      _name: "CSidebarNav",
      _children: _children
    }
  ]
}

export default { menu };
